import React from "react"
import { css } from "@emotion/react"
import Seo from "~components/Seo"
import Section from "~components/Section"
import LoginForm from "~components/blocks/LoginForm"
import RegisterForm from "~components/blocks/RegisterForm"
import Button from "~components/blocks/Button"
import { breakpoints } from '~styles/global'
import { useAccount } from '~context/siteContext'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from '~components/RichText'

const IndexPage = () => {
  const { account, setAccount, logOut } = useAccount()

  const {accountLoginText, accountRegisterText} = useSiteSettings()

  const { mobile, tablet } = breakpoints

  if(account === undefined) return <Seo title="Account"/>

	let message

	if(account?.customer?.tags.includes('wholesale')){
		message = 
		<>
		<p>You're logged in as {account?.customer?.firstName} {account?.customer?.lastName}. Your trade account has been approved and one of our team members will be in touch with you shortly to go through our trade discount scheme with you.</p>
		</>
	} else if(account?.customer?.tags.includes('awaiting approval')) {
		message = <p>You're logged in as {account?.customer?.firstName} {account?.customer?.lastName}.<br/><br/>
		Thank you for your trade account submission, our team is currently reviewing your details and you will be notified when your trade account has been created.</p>
	} else {
		message = <p>You're logged in as {account?.customer?.firstName} {account?.customer?.lastName}</p>
	}

  return (
    <>
    <Seo title="Account" url="/account"/>
    {!account ?
      (
        <Section>
          <LoginForm 
            text={accountLoginText}
            css={css`
              grid-column: 3 / 6;
              margin-top: 93px;
              ${tablet}{
                grid-column: 2 / 6;
              }
              ${mobile}{
                grid-column: span 12;
              }
            `} />
          <RegisterForm 
            text={accountRegisterText}  
            css={css`
              grid-column: 8 / 11;
              margin-top: 93px;
              ${tablet}{
                grid-column: 8 / 12;
              }
              ${mobile}{
                grid-column: span 12;
              }
            `} />
        </Section>
      ) : (
        <Section>
          <div css={css`
            grid-column: 1 / 6;
            margin-top: 60px;
            ${mobile}{
                grid-column: span 12;
              }
            `}>
            {message}
            <Button onClick={() => logOut()} css={css`margin-top: 30px;`}>
              Log out
            </Button>
          </div>
        </Section>
      )
    }
    </>
  )
}

export default IndexPage
