import React, { useState } from 'react'
import { navigate } from "gatsby"
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import axios from 'axios'
import { breakpoints } from '~styles/global'
import Button from '~components/blocks/Button'
import RichText from '~components/RichText'
import Select from '~components/blocks/Select'
import { useAccount } from '~context/siteContext'
import { useQueryParams, StringParam } from 'use-query-params'
import CheckBox from '~components/blocks/CheckBox'

const RegisterForm = ({ className, text }) => {
  const { tablet } = breakpoints
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState([])
	const [trade, setTrade] = useState([])
  const [loading, setLoading] = useState(false)
  const { setAccount } = useAccount()

  const [urlParams, setUrlParams] = useQueryParams({
    redirect: StringParam
  })

  const validate = e => {
    e.preventDefault()
    const newErrors = []

		if(!values.fname) newErrors.push({
      field: 'fName',
      message: 'The first name field is required'
    })

    if(!values.email) newErrors.push({
      field: 'email',
      message: 'The email field is required'
    })

    if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
      field: 'email',
      message: 'Please check your email address'
    })

    if(!values.password) newErrors.push({
      field: 'password',
      message: 'The password field is required'
    })

    if(values.password && values.password.length < 5){
      newErrors.push({
        field: 'password',
        message: 'Your password must be at least 5 characters'
      })
    }

		if(!values.website && values.trade) newErrors.push({
      field: 'website',
      message: 'Your web address is required'
    })

		if(!values.businessName && values.trade) newErrors.push({
      field: 'businessName',
      message: 'Your business name is required'
    })

		if(!values.abn && values.trade) newErrors.push({
      field: 'abn',
      message: 'Your ABN is required'
    })

    if(newErrors.length === 0){
      createAccount()
    }
    else{
      setErrors(newErrors)
    }
  }

	const formatPhoneNumber = p => {
		let phone = p?.replace(/\s+/g, '');
		if(phone?.startsWith("+61")){
			return phone
		} else if(phone?.startsWith("0") || phone?.startsWith(0)){
			return '+61' + phone?.substring(1)
		} else {
			return '+61' + phone
		}
	}

  const createAccount = () => {
    setLoading(true)
    axios({
      url: '/api/register',
      method: 'post',
      data: {
        email: values.email,
        password: values.password,
        firstName: values.fname,
        lastName: values.lname,
        phone: values.phone === "" ? undefined : formatPhoneNumber(values.phone),
				trade: values.trade,
				businessName: values.businessName,
				abn: values.abn,
				website: values.website,
				state: values.state
      }
    }).then(result => {
      setLoading(false)
      if(result.data.error){
        console.log(result.data.error)
        setErrors([{
          field: null,
          message: result.data.error === 'Email has already been taken'
          ? "Look's like you already have an account, please try logging in."
          : result.data.error
        }])
      } else {
        setAccount(result.data)
        if(urlParams?.redirect){
          window.setTimeout(() => {
            navigate(`/${urlParams.redirect}`)
          }, 800)
        }
      }
      return null
    })
    .catch(error => {
      setLoading(false)
      setErrors([{
        field: null,
        message: "Sorry we can't sign you up right now, please try later."
      }])
      console.log(`The error: ${error}`)
    })
    return false
  }

  return (
    <form
      className={className}
      onSubmit={e => validate(e)}
      css={css`
        background: var(--beige);
        cursor: ${loading ? 'wait' : ''};
        * {
          cursor: ${loading ? 'wait' : ''};
        }
        h2{
          margin-bottom: 31px;
          ${tablet}{
            margin-bottom: 36px;
          }
        }

        .h6{
          margin-bottom: 45px;
          ${tablet}{
            margin-bottom: 20px;
          }
        }

        input{
          margin-bottom: 31px;
          ${tablet}{
            margin-bottom: 18px;
          }
        }
      `}
    >
        <h2>Register</h2>
        <div className="h6"><RichText content={text}/></div>
				<CheckBox onChange={val => setValues({...values, trade: val})} css={css`
					margin-bottom: 0;
					label, .h6{
						margin-bottom: 20px;
					}
					input{
						margin-bottom: 0;
					}
				`}>
					I am registering for a trade account
				</CheckBox>
        <input
          type="text"
          placeholder="First Name*"
          id="fname"
          name="fname"
          onChange={e => setValues({...values, fname: e.target.value})}
        />
        <input
          type="text"
          placeholder="Last Name"
          id="lname"
          name="lname"
          onChange={e => setValues({...values, lname: e.target.value})}
        />
        <input
          type="email"
          placeholder="Email*"
          onChange={e => setValues({...values, email: e.target.value})}
        />
         <input
          type="password"
          placeholder="Password*"
          onChange={e => setValues({...values, password: e.target.value})}
        />
        <input
          type="phone"
          placeholder="Phone"
          onChange={e => setValues({...values, phone: e.target.value})}
        />
				{values.trade &&
					<>
						<input
							type="text"
							placeholder="Business Name*"
							id="businessName"
							name="businessName"
							onChange={e => setValues({...values, businessName: e.target.value})}
							/>
						<input
							type="number"
							placeholder="ABN*"
							id="abn"
							name="abn"
							onChange={e => setValues({...values, abn: e.target.value})}
							/>
						<input
							type="text"
							id="website"
							name="website"
							placeholder="Web Address*"
							onChange={e => setValues({...values, website: e.target.value})}
						/>
					</>
				}
        <Select placeholder="State"
            onChange={value => setValues({...values, state: value.value})}
            name='state'
            background='var(--beige)'
            options={[
             { value: 'vic', label: 'Victoria' },
             { value: 'nsw', label: 'New South Wales' },
             { value: 'qld', label: 'Queensland' },
             { value: 'wa', label: 'Western Australia' },
             { value: 'sa', label: 'South Australia' },
             { value: 'nt', label: 'Northern Territory' },
             { value: 'act', label: 'ACT' },
             { value: 'tas', label: 'Tasmania' },
             ]}
             />
        {errors.length > 0 &&
          <div className="h5" css={css`
            color: var(--orange);
            margin-top: 30px;
          `}>
            {errors.map(error => <p className="h5" key={error.message}>{error.message}</p>)}
          </div>
        }
        <Button onClick={e => validate(e)} role="submit" css={css`
          margin-top: 50px;
          ${tablet}{
            margin-top: 40px;
          }
        `}>
          {loading ? 'Loading' : 'Register'}
        </Button>
    </form>
  )
}

RegisterForm.propTypes = {
}

RegisterForm.defaultProps = {
}

export default RegisterForm
