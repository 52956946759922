import React, { useState } from 'react'
import { Link, navigate } from "gatsby"
import axios from 'axios'
import { css } from '@emotion/react'
import { breakpoints } from '~styles/global'
import Button from '~components/blocks/Button'
import { useAccount } from '~context/siteContext'
import { useQueryParams, StringParam } from 'use-query-params'
import RichText from '~components/RichText'

const LoginForm = ({ className, text }) => {
  const { tablet } = breakpoints
  const [values, setValues] = useState({email: "", password: ""})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { account, setAccount } = useAccount()
  const [urlParams, setUrlParams] = useQueryParams({
    redirect: StringParam
  })

  const getToken = e => {
    e.preventDefault()
    setLoading(true)
    axios({
      url: '/api/login',
      method: 'post',
      data: {
        email: values.email,
        password: values.password
      }
    }).then(result => {
      setLoading(false)
      if(result.data.error){
        setError(true)
        console.log(result.data.error)
      } else {
        setError(false)
        setAccount(result.data)
        if(urlParams?.redirect){
          window.setTimeout(() => {
            navigate(`/${urlParams.redirect}`)
          }, 800)
        }
      }
      return null
    })
    .catch(error => {
      setLoading(false)
      console.log(error)
    })
    return false
  }

  return (
    <form
      className={className}
      onSubmit={e => getToken(e)}
      css={css`
        background: var(--beige);
        cursor: ${loading ? 'wait' : ''};
        * {
          cursor: ${loading ? 'wait' : ''};
        }

        h2{
          margin-bottom: 31px;
          ${tablet}{
            margin-bottom: 36px;
          }
        }

        .h6{
          margin-bottom: 45px;
          ${tablet}{
            margin-bottom: 20px;
          }
        }

        input{
          margin-bottom: 31px;
          ${tablet}{
            margin-bottom: 18px;
          }
        }

        > .h5{
          margin-bottom: 42px;
          display: inline-block;
          ${tablet}{
            margin-top: 12px;
            margin-bottom: 30px;
          }
        }
      `}
    >
        <h2>Login</h2>
        <div className="h6"><RichText content={text}/></div>
        <input
          type="email"
          placeholder="Email"
          onFocus={() => setError(false)}
          onChange={e => setValues({...values, email: e.target.value})}
        />
        <input
          type="password"
          placeholder="Password"
          onFocus={() => setError(false)}
          onChange={e => setValues({...values, password: e.target.value})}
        />
        {error &&
          <div css={css`
            grid-column: span 2;
            margin-top: 20px;
            margin-bottom: 20px;
            color: var(--orange);
          `}>
            <p className="h5">Please check your email and password.</p>
          </div>
        }
        <Link className="h5" to='/forgot'>Can't remember your password?</Link>
        <Button onClick={() => null} type="submit">
          {loading ? 'Loading' : 'Login'}
        </Button>
    </form>
  )
}

LoginForm.propTypes = {
}

LoginForm.defaultProps = {
}

export default LoginForm
